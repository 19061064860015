<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="900"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        
                        <v-form ref="form" lazy-validation>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Документ-основание") }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="9">
                                    <div class="onlyReadData">
                                        {{ document }}
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t("Тип_контроля")}}</label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-select
                                        v-model="controlType"
                                        :items="controlTypes"
                                        :item-text="item => $refLocale(item, $i18n.locale)"
                                        item-value="id"
                                        hide-details
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                    >
                                    </v-select>
                                </v-col>

                            </v-row>

                            <v-row no-gutters v-if="controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t("Тип_периода")}}</label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-select
                                        v-model="periodControlType"
                                        :items="periodControlTypes"
                                        :item-text="item => $refLocale(item, $i18n.locale)"
                                        item-value="id"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        @change="calcControlDate"
                                    >
                                    </v-select>
                                </v-col>

                            </v-row>

                            <v-row no-gutters v-if="periodControlType == 1 && controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="more-inputs-in-row">
                                        <span>{{ $t("Каждые") }}</span>
                                        <v-text-field
                                            v-model="everydayDelta"
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            type="number"
                                            :rules="everydayPeriodRule"
                                            class="cust-inputnumber"
                                            min="1"
                                            max="365"
                                            @input="calcControlDate"
                                            @change="calcControlDate"
                                        ></v-text-field>

                                        <span>{{ $t("дня/дней,_начиная_с") }}</span>

                                        <v-menu 
                                            v-model="everydayDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="everydayDate | formattedDate"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    class="datepick-input"
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-date-picker
                                                v-model="everydayDate"
                                                color="teal"
                                                :first-day-of-week="1"
                                                @change="calcControlDate"
                                                :min="todayDate"
                                                :allowed-dates="allowedDates"
                                                @input="everydayDateMenu = false"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                        
                                    </div>
                                </v-col>
                            </v-row> 

                            <v-row no-gutters v-if="periodControlType == 2 && controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="more-inputs-in-row">
                                        <span>{{ $t("Каждый") }}</span> 
                                        <v-select
                                            v-model="workWeekDay"
                                            :items="workWeekDays"
                                            :item-text="item => $refLocale(item, $i18n.locale)"
                                            item-value="id"
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            append-icon="fas fa-chevron-down"
                                            :menu-props="{ bottom: true, offsetY: true }"
                                            @change="calcControlDate"
                                        >
                                        </v-select>
                                    </div>
                                </v-col>
                            </v-row> 

                            <v-row no-gutters v-if="periodControlType == 3 && controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="more-inputs-in-row">
                                        <span>{{ $t("Каждые") }}</span>
                                        <v-text-field
                                            v-model="monthlyDelta"
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            type="number"
                                            :rules="monthlyPeriodRule"
                                            class="cust-inputnumber"
                                            min="1"
                                            max="12"
                                            @input="calcControlDate"
                                            @change="calcControlDate"
                                        ></v-text-field>

                                        <span>{{ $t("месяца/-ев,_начиная_с") }}</span>

                                        <v-menu 
                                            v-model="monthlyDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="monthlyDate | formattedDate"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    class="datepick-input"
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-date-picker
                                                v-model="monthlyDate"
                                                color="teal"
                                                :first-day-of-week="1"
                                                @change="calcControlDate"
                                                :min="todayDate"
                                                :allowed-dates="allowedDates"
                                                @input="monthlyDateMenu = false"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                        
                                    </div>
                                </v-col>
                            </v-row> 

                            <v-row no-gutters v-if="periodControlType == 4 && controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="more-inputs-in-row">
                                        <span>{{ $t("Каждый") }}</span>
                                        <v-text-field
                                            v-model="kvartalDelta"
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            type="number"
                                            :rules="kvartalPeriodRule"
                                            class="cust-inputnumber"
                                            min="1"
                                            max="60"
                                            @input="calcControlDate"
                                            @change="calcControlDate"
                                        ></v-text-field>

                                        <span>{{ $t("день,_нового_отчетного_периода") }}</span>
                                    </div>
                                </v-col>
                            </v-row> 

                            <v-row no-gutters v-if="periodControlType == 5 && controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="more-inputs-in-row">
                                        <span>{{ $t("Раз_в_год,_начиная_с") }}</span>
                                        
                                        <v-menu 
                                            v-model="anuallyDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="anuallyDate | formattedDate"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    class="datepick-input"
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-date-picker
                                                v-model="anuallyDate"
                                                color="teal"
                                                :first-day-of-week="1"
                                                @change="calcControlDate"
                                                :min="todayDate"
                                                @input="anuallyDateMenu = false"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                        
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="periodControlType == 6 && controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="more-inputs-in-row">
                                        <span>{{ $t("Начиная_с") }}</span>
                                        
                                        <v-menu 
                                            v-model="randomDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="randomDate | formattedDate"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    class="datepick-input"
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-date-picker
                                                v-model="randomDate"
                                                color="teal"
                                                :first-day-of-week="1"
                                                @change="calcControlDate"
                                                :min="todayDate"
                                                @input="randomDateMenu = false"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                        
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="[1, 3, 4, 5, 6].includes(periodControlType) && controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t("Учитывать_выходные_дни")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-switch
                                        v-model="isConsiderWeekends"
                                        inset
                                        hide-details
                                        class="cust-switch"
                                    />
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t("Контрольный_срок")}}</label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-menu 
                                        v-model="controlDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                        :disabled="controlType == 101"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="controlDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                class="datepick-input"
                                                required
                                                :rules="controlType != 101 ? requiredRule : []"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="controlDate"
                                            @input="controlDateMenu = false"
                                            color="teal"
                                            :min="minDate"
                                            :max="maxData"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>

                                    <div v-if="controlType != 101" class="field-hint grey--text">
                                        <i>{{ $t('Рекомендуемый_срок') + ': ' + supposedControlDate }}</i>
                                    </div>

                                </v-col>

                            </v-row>

                            <v-row no-gutters v-if="controlType == 101">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{$t("Окончание_периода")}}</label>    
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-menu 
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="periodEndDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="periodEndDate"
                                            color="teal"
                                            :first-day-of-week="1"
                                            :min="controlDate ? controlDate : todayDate"
                                            @input="endDateMenu = false"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                        </v-form>

                    </v-card-text>
                </v-card>                        
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="onOk"
                    v-if="isValid"
                >
                    {{ $t("Регистрировать") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="onCancel"
                >
                    {{ $t("Закрыть") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "RegisterSectionDlg",
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            title: "Регистрация_пункта",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            controlTypes: [
                { id: 1, Value: this.$t("Контроль") },
                { id: 2, Value: this.$t("Особый_контроль") },
                { id: 101, Value: this.$t("Периодический_контроль") },
            ],
            controlDateMenu: false,
            endDateMenu: false,
            periodControlType: 1,
            workWeekDay: 1,
            everydayDateMenu: false,
            everydayDate: '',
            everydayDelta: 1,
            monthlyDateMenu: false,
            monthlyDate: '',
            monthlyDelta: 1,
            anuallyDateMenu: false,
            anuallyDate: '',
            kvartalDelta: 1,
            todayDate: this.$moment().format('YYYY-MM-DD'),
            randomDateMenu: false,
            randomDate: '',
            isValid: false,
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            integerNotNull: 'getIntegerNotNullRule',
            everydayPeriodRule: 'getEverydayPeriodRule',
            monthlyPeriodRule: 'getMonthlyPeriodRule',
            kvartalPeriodRule: 'getKvartalPeriodRule'
        }),
        ...mapGetters(
            'global/dialogs/registerSection',
            { 
                visible: 'isVisible',
                document: 'getDocument',
                minDate: 'getMinDate',
                maxData: 'getMaxDate',
                supposedControlDate: 'getSupposedControlDate'
            }),
        ...mapGetters('documents/references', { periodControlTypes: 'GetPeriodControlTypes', workWeekDays: 'GetWorkWeekDays'}),
        controlType: {
            get: function() {
                return this.$store.getters['global/dialogs/registerSection/getControlType'];
            },
            set: function(v) {
                this.$store.dispatch('global/dialogs/registerSection/changeControlType', v);
            }
        },
        controlDate: {
            get: function() {
                return this.$store.getters['global/dialogs/registerSection/getControlDate'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/registerSection/SET_CONTROL_DATE', v); 
            }
        },
        periodDeltaType:{
            get: function() {
                return this.$store.getters['global/dialogs/registerSection/getPeriodDeltaType'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/registerSection/SET_PERIOD_DELTA_TYPE', v); 
            }
        },
        periodDelta:{
            get: function() {
                return this.$store.getters['global/dialogs/registerSection/getPeriodDelta'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/registerSection/SET_PERIOD_DELTA', v); 
            }
        },
        periodEndDate: {
            get: function() {
                return this.$store.getters['global/dialogs/registerSection/getPeriodEndDate'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/registerSection/SET_PERIOD_END_DATE', v); 
            }
        },
        isConsiderWeekends: {
            get: function() {
                return this.$store.getters['global/dialogs/registerSection/getIsConsiderWeekend'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/registerSection/SET_CONSIDER_WEEKEND', v); 
            }
        }
    },
    methods: {
        ...mapActions('global/dialogs/registerSection', [ 'ok', 'cancel' ]),
        async onOk() {
            this.controlDateMenu = false;
            await this.ok();
        },
        async onCancel() {
            this.controlDateMenu = false;
            await this.cancel();
        },
        changeControlType(){
            if (this.controlType != 101){
                this.periodDeltaType = 0;
                this.periodDelta = null;
                this.periodEndDate = null;
            }
        },
        calcControlDate(){
            let date = new Date();
            this.periodEndDate = '';
            switch(this.periodControlType){
                case 1:
                    this.controlDate = this.everydayDate;
                    this.periodDeltaType = 1;
                    this.periodDelta = this.everydayDelta;
                    break;
                case 2:
                    date.setDate(date.getDate() + ((this.workWeekDay - date.getDay() + 7) % 7 ));
                    this.controlDate =  date;
                    this.periodDeltaType = 1;
                    this.periodDelta = 7;
                    break;
                case 3:
                    this.controlDate = this.monthlyDate;
                    this.periodDeltaType = 2;
                    this.periodDelta = this.monthlyDelta;
                    break;
                case 4:{
                        let controlDateYear = date.getFullYear();
                        let controlDateMonth = Math.floor((date.getMonth() + 1) / 3) * 3;
                        date = new Date(controlDateYear, controlDateMonth, this.kvartalDelta, 0, 0, 0, 0);
                        if (date < new Date()){
                            controlDateMonth = controlDateMonth+3
                            if (controlDateMonth > 11){
                                controlDateMonth = controlDateMonth - 11;
                                controlDateYear++;  
                            }
                            date = new Date(controlDateYear, controlDateMonth, this.kvartalDelta, 0, 0, 0, 0);
                        }
                        this.controlDate =  date;
                        this.periodDeltaType = 2;
                        this.periodDelta = 3;
                    }
                    break;
                case 5:
                    this.controlDate = this.anuallyDate;
                    this.periodDeltaType = 3;
                    this.periodDelta = 1;
                    break;
                case 6:
                    this.controlDate = this.randomDate;
                    this.periodDeltaType = 4;
                    this.periodDelta = 0;
                    break;
            }
        },
        allowedDates(val) {
            return this.isConsiderWeekends ? ![0, 6].includes(new Date(val).getDay()) : true;
        }
    },
    updated: function () {
        this.isValid = this.$refs?.form?.validate();
    }
}
</script>